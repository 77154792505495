import React from "react"
import { Helmet } from "react-helmet"
import {
  Box,
  Container,
} from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby"
import Navbar from '../components/Navbar'

export default function Layout(props) {
  const data = useStaticQuery(
    graphql`
    query {
        site {
            siteMetadata {
              title
              description
            }
        }
    }
    `
  )
  const { siteMetadata } = data.site
  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteMetadata.title}</title>
        <link rel="canonical" href="http://podcast.overbuild.io" />
        <meta name="description" content={siteMetadata.description} />
      </Helmet>
      <Box p={[1, 8]}>
        <Navbar />
        <Box>
          {props.children}
        </Box>
      </Box>
    </Container>
  );
}

import React from "react"
import { useLocation } from "@reach/router"
import { Link as GatsbyLink } from "gatsby"

import {
    VStack,
    Heading,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Link,
    Badge,
} from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"

const Navbar = () => {
    const location = useLocation()
    const data = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    youtube
                }
            }
        }
        `
    )
    const { siteMetadata } = data.site
    const links = [{
        href: '/',
        text: 'Home'
    }, {
        href: '/about/',
        text: 'About',
    }, {
        href: '/subscribe/',
        text: 'Subscribe'
    }, {
        href: siteMetadata.youtube,
        text: 'YouTube'
    }]
    return (
        <VStack p={[0, 3]}>
            <Heading size="sm" isTruncated>
                <Link as={GatsbyLink} to='/'>
                    overbuild
                </Link>
                <Badge ml="1" variant="subtle" colorScheme="green">
                    beta
                </Badge>
            </Heading>
            <Breadcrumb fontWeight="medium" fontSize="sm">
                {links.map(link => (
                    <BreadcrumbItem isCurrentPage={link.href === location.pathname} key={link.href}>
                        <BreadcrumbLink as={GatsbyLink} to={link.href}>{link.text}</BreadcrumbLink>
                        
                    </BreadcrumbItem>    
                ))}
            </Breadcrumb>
        </VStack>
    )
}

export default Navbar